import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Map, { NavigationControl, FullscreenControl, ScaleControl, GeolocateControl, MapRef } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MarkerComponent } from "./Markers/MarkerComponent";
import Filters from "./Filters"; // Импортируйте компонент фильтров
import useGlobalStore from "../store/useGlobalStore";
import { SquadModal } from "./Modals/SquadModal";
import { TaskModal } from "./Modals/TaskModal";
import { GatheringPointModal } from "./Modals/GatheringPointModal";
import { IncidentModal } from "./Modals/IncidentModal";
import { MemberModal } from "./Modals/MemberModal";
import RouteMap from "./Route";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

const MAPBOX_TOKEN = "pk.eyJ1IjoidmthYnoiLCJhIjoiY2x4dnR3cnhpMHVpODJrc2FwcmNuOG02aSJ9.TtSQk5NFSldQqP5XGs06hA"; // Замените на ваш токен

export const MapComponent: React.FC = () => {
  const {
    druzhiny,
    modalGatheringPoint,
    modalPerson,
    modalReport,
    setPersonModal,
    setReportModal,
    setGatheringPointModal,
    modalTask,
    setDruzhinaModal,
    modalDruzhina,
    selectedDruzhina,
    filters,
    newPeopleData,
    newTasks,
    newGatheringPoints,
    setTaskModal,
    newReports,
    currentShift, // Получаем данные маршрута (shift) из состояния
  } = useGlobalStore();

  const mapRef = useRef<MapRef>(null); // Создаем ref для карты

  useEffect(() => {}, [mapRef]);

  if (mapRef.current) {
    const language = new MapboxLanguage({ defaultLanguage: "ru" });
    mapRef.current.addControl(language);
  }

  const markersSquad = useMemo(() => {
    if (!filters.squadHeadquarters) return undefined;
    const filteredDruzhiny = selectedDruzhina ? druzhiny.filter((item) => item.id === selectedDruzhina) : druzhiny;

    return filteredDruzhiny
      .filter((item) => item.location) // Отфильтровываем только те, у которых есть location
      .map((item) => (
        <MarkerComponent
          onClick={() => setDruzhinaModal(item)}
          key={item.id}
          type="Squad"
          latitude={item.location[1]}
          longitude={item.location[0]}
          data={{ name: item.name, squadSize: item.participants.length }}
        />
      ));
  }, [druzhiny, selectedDruzhina, filters]);

  const markersUser = useMemo(() => {
    return newPeopleData
      .filter((item) => {
        if (!item.last_geo) {
          return false;
        }

        if (!filters.sosSignals && item.active_sos_signal) {
          return false;
        }

        if (!filters.commanders && item.role !== "VIGILANTE") {
          return false;
        }

        if (!filters.volunteers && item.role === "VIGILANTE") {
          return false;
        }

        return true;
      }) // Отфильтровываем только те, у которых есть location
      .map((item) => (
        <MarkerComponent
          onClick={() => setPersonModal(item)}
          key={`${item.id}_MARKER_USER`}
          type="User"
          latitude={item.last_geo?.location.lat ?? 0}
          longitude={item.last_geo?.location.lon ?? 0}
          data={{ user: item }}
        />
      ));
  }, [newPeopleData, filters]);

  const markersTask = useMemo(() => {
    return newTasks
      .filter((item) => {
        if (!item.location.lat || !item.location.lon) {
          return false;
        }
        if (!filters.closedTasks && item.status === "completed") {
          return false;
        }
        if (!filters.openTasks && item.status === "pending") {
          return false;
        }

        return true;
      }) // Отфильтровываем только те, у которых есть location
      .map((item) => (
        <MarkerComponent
          onClick={() => {
            setTaskModal(item);
          }}
          key={`${item.id}_MARKER_TASK`}
          type="Task"
          latitude={item.location.lat ?? 0}
          longitude={item.location.lon ?? 0}
          data={{ task: item }}
        />
      ));
  }, [newTasks, filters]);

  const markersGatheringPoints = useMemo(() => {
    return newGatheringPoints
      .filter((item) => {
        if (!item.location.lat && !item.location.lon) {
          return false;
        }

        if (!filters.collectionPoints) {
          return false;
        }

        return true;
      }) // Отфильтровываем только те, у которых есть location
      .map((item) => (
        <MarkerComponent
          onClick={() => setGatheringPointModal(item)}
          key={`${item.id}_MARKER_GATHERING_POINT`}
          type="Gathering_Point"
          latitude={item.location.lat ?? 0}
          longitude={item.location.lon ?? 0}
          data={{ point: item }}
        />
      ));
  }, [newGatheringPoints, filters]);

  const reportTelegramPoints = useMemo(() => {
    return newReports
      .filter((item) => {
        if (!item.location.lat && !item.location.lon) {
          return false;
        }

        if (!filters.telegramIncidents) {
          return false;
        }

        return true;
      }) // Отфильтровываем только те, у которых есть location
      .map((item) => (
        <MarkerComponent
          onClick={() => setReportModal(item)}
          key={`${item.id}_MARKER_REPORT`}
          type="Report"
          latitude={item.location.lat ?? 0}
          longitude={item.location.lon ?? 0}
          data={{ report: item }}
        />
      ));
  }, [newGatheringPoints, filters]);

  // // Эффект для подгонки карты под маршрут (shift)
  // useEffect(() => {
  //   if (currentShift && currentShift.route.length > 0 && mapRef.current) {
  //     // Извлекаем минимальные и максимальные координаты для fitBounds
  //     const longitudes = currentShift.route.map((point) => point.lon);
  //     const latitudes = currentShift.route.map((point) => point.lat);

  //     const maxLon = Math.max(...longitudes);
  //     const minLon = Math.min(...longitudes);
  //     const maxLat = Math.max(...latitudes);
  //     const minLat = Math.min(...latitudes);

  //     // Обновляем карту, устанавливая границы (fitBounds)
  //     mapRef.current.fitBounds(
  //       [
  //         [minLon, minLat], // Минимальная точка (левый нижний угол)
  //         [maxLon, maxLat], // Максимальная точка (правый верхний угол)
  //       ],
  //       {
  //         padding: 40, // Добавим отступ для лучшей видимости
  //         duration: 1000, // Анимация
  //       }
  //     );
  //   }
  // }, [currentShift]);

  return (
    <>
      <div style={{ height: "75vh", width: "100%", position: "relative" }}>
        <Map
          ref={(ref) => {
            const language = new MapboxLanguage({ defaultLanguage: "ru" });
            ref?.addControl(language);
            // @ts-ignore
            mapRef.current = ref;
          }} // Передаем реф для карты
          initialViewState={{
            latitude: 55.751244, // Координаты Москвы (можете заменить на свои)
            longitude: 37.618423,
            zoom: 10,
          }}
          style={{ width: "100%", height: "100%" }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          onStyleData={(data) => {
            console.log("data", data);
            if (data.dataType === "style") {
            }
          }}
          mapboxAccessToken={MAPBOX_TOKEN}
        >
          {/* Настройки карты */}
          <NavigationControl position="top-left" />
          <FullscreenControl position="top-left" />
          <ScaleControl />
          <GeolocateControl position="top-left" />
          {markersSquad}
          {markersUser}
          {markersTask}
          {markersGatheringPoints}
          {reportTelegramPoints}
          <RouteMap />
        </Map>

        {/* Размещение фильтров в верхнем правом углу */}
        <div style={{ position: "absolute", top: 10, right: 20, zIndex: 1 }}>
          <Filters />
        </div>
      </div>
      <SquadModal
        onHide={() => {
          setDruzhinaModal(null);
        }}
        item={modalDruzhina}
      />
      <TaskModal
        item={modalTask}
        onHide={() => {
          setTaskModal(null);
        }}
      />
      <GatheringPointModal
        onHide={() => {
          setGatheringPointModal(null);
        }}
        item={modalGatheringPoint}
      />
      <IncidentModal
        onHide={() => {
          setReportModal(null);
        }}
        item={modalReport}
      />
      <MemberModal
        onHide={() => {
          setPersonModal(null);
        }}
        item={modalPerson}
      />
    </>
  );
};
