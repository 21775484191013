import React, { useMemo } from "react";
import { Source, Layer, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import useGlobalStore from "../store/useGlobalStore";

const RouteMap: React.FC = () => {
  const { currentShift } = useGlobalStore(); // currentShift — это массив CurrentShiftRoute[]

  // Функция для генерации случайного цвета
  const getRandomBrightColor = (): string => {
    // Генерируем случайный оттенок (hue) от 0 до 360 градусов
    const hue = Math.floor(Math.random() * 360);
    // Устанавливаем высокую насыщенность и яркость для получения яркого цвета
    const saturation = 90; // Насыщенность от 0 до 100%
    const lightness = 60; // Яркость от 0 до 100%

    // Преобразуем HSL в RGB
    const h = hue / 360;
    const s = saturation / 100;
    const l = lightness / 100;

    let r: number, g: number, b: number;

    if (s === 0) {
      r = g = b = l; // Если насыщенность 0, то цвет в градациях серого
    } else {
      const hue2rgb = (p: number, q: number, t: number): number => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    const toHex = (x: number): string => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  // Вычисляем маршруты для каждого пользователя
  const userRoutesData = useMemo(() => {
    return currentShift.map(({ shifts, user }) => {
      const userColor = getRandomBrightColor(); // Генерируем цвет для каждого пользователя

      // Фильтруем смены с непустым маршрутом
      const validShifts = shifts.filter((shift) => shift.route && shift.route.length > 0);

      const userShiftsData = validShifts.map((shift) => {
        const coordinates = shift.route.map((point) => [point.lon, point.lat]);
        return {
          id: shift.id,
          routeCoordinates: coordinates,
          startPoint: coordinates[0] as [number, number],
          endPoint: coordinates[coordinates.length - 1] as [number, number],
          user,
          color: userColor, // Используем цвет пользователя для его смен
        };
      });
      return userShiftsData;
    });
  }, [currentShift]);

  // Превращаем вложенный массив в плоский массив
  const shiftsData = userRoutesData.flat();

  const routeLayer = (id: number, color: string): any => ({
    id: `route-${id}`,
    type: "line",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": color, // Цвет маршрута пользователя
      "line-width": 4,
    },
  });

  const endPointLayer = (id: number): any => ({
    id: `end-point-${id}`,
    type: "circle",
    paint: {
      "circle-radius": 9,
      "circle-color": "#FF0000", // Цвет конечной точки
    },
  });

  const dashedLineLayer = (id: number, color: string): any => ({
    id: `dashed-line-${id}`,
    type: "line",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": color, // Используем цвет пользователя
      "line-width": 2,
      "line-dasharray": [2, 2],
    },
  });

  if (shiftsData.length === 0) {
    return null;
  }

  return (
    <>
      {currentShift.map(({ shifts, user }, userIndex) => {
        // Получаем данные смен с маршрутом для текущего пользователя
        const userShifts = shiftsData.filter((shift) => shift.user?.id === user?.id);

        if (userShifts.length === 0) {
          return null; // Пропускаем пользователя, если у него нет смен с маршрутом
        }

        return (
          <React.Fragment key={`user-${user?.id || userIndex}`}>
            {userShifts.map(({ id, routeCoordinates, startPoint, endPoint, color }, index) => (
              <React.Fragment key={id}>
                {/* Рендерим маршрут для каждой смены пользователя */}
                <Source
                  id={`route-${id}`}
                  type="geojson"
                  data={{
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "LineString",
                      coordinates: routeCoordinates,
                    },
                  }}
                >
                  <Layer {...routeLayer(id, color)} />
                </Source>

                {/* Начальная точка с аватаром пользователя */}
                {index === 0 && startPoint && user && (
                  <>
                    <Marker longitude={startPoint[0]} latitude={startPoint[1]} offset={[0, 17]} anchor="bottom">
                      <img
                        src={user.avatar ? user.avatar : require("../assets/markers/avatar.png")}
                        alt="User Avatar"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: "2px solid #3f7fff",
                        }}
                      />
                    </Marker>
                    {/* Добавляем вывод данных в консоль для отладки */}
                    {console.log("User Avatar:", user.avatar, "Start Point:", startPoint)}
                  </>
                )}

                {/* Конечная точка */}
                {endPoint && (
                  <Source
                    id={`end-point-${id}`}
                    type="geojson"
                    data={{
                      type: "Feature",
                      properties: {},
                      geometry: {
                        type: "Point",
                        coordinates: endPoint,
                      },
                    }}
                  >
                    <Layer {...endPointLayer(id)} />
                  </Source>
                )}

                {/* Если есть следующая смена, соединяем конец этой смены с началом следующей пунктирной линией */}
                {index < userShifts.length - 1 && (
                  <Source
                    id={`dashed-line-${id}`}
                    type="geojson"
                    data={{
                      type: "Feature",
                      properties: {},
                      geometry: {
                        type: "LineString",
                        coordinates: [
                          endPoint, // Конечная точка текущей смены
                          userShifts[index + 1].startPoint, // Начальная точка следующей смены
                        ],
                      },
                    }}
                  >
                    <Layer {...dashedLineLayer(id, color)} />
                  </Source>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default RouteMap;
